define('swap-system/mirage/routes/swap-approval-details', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (server) {
		server.get('/swapApprovalDetails/lastManualSourceId', function (_ref) {
			var swapApprovalDetails = _ref.swapApprovalDetails;

			var detailsIds = swapApprovalDetails.where({
				equipmentSource: 'Manual'
			}).models.map(function (model) {
				return model.equipmentId;
			});

			detailsIds.sort(function (a, b) {
				return b - a;
			});
			return { equipmentId: detailsIds.length > 0 ? detailsIds[0] : 0 };
		});
		server.put('/swapApprovalDetails/:id', function (_ref2, request) {
			var swapApprovalDetails = _ref2.swapApprovalDetails;

			try {
				var id = request.params.id;
				var attrs = this.normalizedRequestAttrs();

				return swapApprovalDetails.find(id).update(attrs);
			} catch (error) {
				return new _emberCliMirage.Response(500, {}, {
					error: {
						code: 500,
						message: error
					}
				});
			}
		});
	};
});