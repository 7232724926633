define('swap-system/mirage/models/admin-panel-product', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Model.extend({
		subProducts: (0, _emberCliMirage.hasMany)('adminPanelProductSubproduct'),
		availableSubstitutions: (0, _emberCliMirage.hasMany)('adminPanelProductSubstitutionProduct')
	});
});