define('swap-system/routes/approve', ['exports', 'swap-system/mixins/routes/authenticated-route-mixin', 'swap-system/mixins/routes/required-group-mixin', 'swap-system/mixins/routes/log-loader', 'swap-system/mixins/routes/confirmation-modal', 'swap-system/enums/userGroups'], function (exports, _authenticatedRouteMixin, _requiredGroupMixin, _logLoader, _confirmationModal, _userGroups) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requiredGroupMixin.default, _logLoader.default, _confirmationModal.default, {
		requiredGroup: _userGroups.default.swapUser,
		merchant: Ember.inject.service(),
		titleToken: function titleToken(model) {
			return 'Swap Approval #' + model.swapApproval.id;
		},
		model: function model(params) {
			var _this = this;

			this.store.unloadAll('swapRmaSubstitutionSubproduct');

			return Ember.RSVP.hash({
				swapApproval: this.store.findRecord('swapApproval', params.id),
				swapStatus: this.store.findRecord('swapStatus', 1),
				swapStatuses: this.store.findAll('swapStatus'),
				swapApprovalStatuses: this.store.findAll('swapApprovalStatus'),
				substitutionProducts: this.store.findAll('substitutionProduct'),
				substitutionSubproducts: this.store.findAll('substitutionSubproduct'),
				brands: this.store.findAll('brand'),
				orderType: this.store.findAll('orderType'),
				processorTypes: this.store.findAll('processorType'),
				countries: this.store.findAll('country'),
				isLoading: true
			}).then(function (resp) {
				var merchantId = resp.swapApproval.get('mid');
				_this.set('merchant.id', merchantId);

				return Promise.all([Promise.resolve(resp), _this.store.queryRecord('merchant', { mid: merchantId }), _this.store.findAll('product')]);
			}).then(function (_ref) {
				var _ref2 = _slicedToArray(_ref, 3),
				    resp = _ref2[0],
				    merchant = _ref2[1],
				    products = _ref2[2];

				resp.swapApproval.set('merchantAddresses', merchant.get('addresses'));
				resp.merchant = merchant;
				resp.products = products;

				return resp;
			});
		},
		setupController: function setupController(controller, model) {
			this._super(controller, model);
			model.isLoading = false;
		}
	});
});