define('swap-system/mirage/factories/rma-reason', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Factory.extend({
		description: function description(i) {
			return _emberCliMirage.faker.list.cycle('Swap', 'RMA Only', 'POS Invoice', 'POS Upgrade', 'POS Software Change')(i);
		},
		dayPeriod: function dayPeriod(i) {
			return _emberCliMirage.faker.list.cycle(15, 15, 30, 30, 30)(i);
		}
	});
});