define('swap-system/mirage/routes/rma-statuses', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (server) {
		server.get('/rmaStatuses');
		server.get('/rmaStatuses/:id');
	};
});