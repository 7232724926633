define('swap-system/mirage/factories/admin-panel-product-substitution-product', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Factory.extend({
		productName: function productName() {
			return _emberCliMirage.faker.random.words();
		},
		modelNo: function modelNo() {
			return _emberCliMirage.faker.random.words();
		}
	});
});