define('swap-system/mirage/factories/rma-fee', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Factory.extend({
		isBilled: function isBilled() {
			return false;
		},
		isApplicable: function isApplicable() {
			return false;
		},
		feeName: function feeName() {
			return _emberCliMirage.faker.random.word() + ' ' + _emberCliMirage.faker.random.word();
		},
		billAmount: function billAmount() {
			return Math.round(_emberCliMirage.faker.random.number());
		},
		dueDate: function dueDate() {
			return new Date();
		}
	});
});