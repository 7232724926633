define('swap-system/mirage/models/rma-approval-detail', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Model.extend({
		rmaApproval: (0, _emberCliMirage.belongsTo)('rmaApproval'),
		substitutionProduct: (0, _emberCliMirage.belongsTo)('product', { inverse: null }),
		product: (0, _emberCliMirage.belongsTo)('product')
	});
});