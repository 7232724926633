define('swap-system/mirage/routes/auth', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (server) {
		server.get('/auth/url', function () {
			return {};
		});
	};
});