define('swap-system/mirage/factories/rma-approval-detail', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Factory.extend({
		equipmentId: function equipmentId() {
			return _emberCliMirage.faker.random.number();
		},
		equipmentSource: function equipmentSource() {
			return _emberCliMirage.faker.random.word();
		},
		productName: function productName() {
			return _emberCliMirage.faker.random.word();
		},
		serialNumber: function serialNumber() {
			return _emberCliMirage.faker.random.uuid();
		},
		terminalNumber: function terminalNumber() {
			return _emberCliMirage.faker.random.uuid();
		},
		ipAddress: function ipAddress() {
			return _emberCliMirage.faker.internet.ip();
		},

		sendReplacement: false
	});
});