define('swap-system/mirage/config', ['exports', 'swap-system/mirage/routes'], function (exports, _routes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function () {
		this.passthrough('/write-coverage');
		this.namespace = '/api/v1';

		_routes.default.auth(this);
		_routes.default.rmas(this);
		_routes.default.rmaDetails(this);
		_routes.default.rmaFees(this);
		_routes.default.rmaApprovals(this);
		_routes.default.rmaApprovalDetails(this);
		_routes.default.rmaReasons(this);
		_routes.default.rmaStatuses(this);
		_routes.default.rmaApprovalStatuses(this);
		_routes.default.swaps(this);
		_routes.default.swapDetails(this);
		_routes.default.swapStatuses(this);
		_routes.default.swapApprovals(this);
		_routes.default.swapApprovalDetails(this);
		_routes.default.swapApprovalComments(this);
		_routes.default.swapApprovalStatuses(this);
		_routes.default.products(this);
		_routes.default.equipment(this);
		_routes.default.merchants(this);
		_routes.default.brands(this);
		_routes.default.serialHistory(this);
		_routes.default.logs(this);
		_routes.default.orderTypes(this);
		_routes.default.conditionTypes(this);
		_routes.default.processorTypes(this);
		_routes.default.substitutionProducts(this);
		_routes.default.substitutionSubproducts(this);
		_routes.default.swapRmaSubstitutionProducts(this);
		_routes.default.swapRmaSubstitutionSubproducts(this);
		_routes.default.adminPanelProducts(this);
		_routes.default.alteredShippingAddress(this);
		_routes.default.defaultShippingAddress(this);
		_routes.default.registrableDevices(this);
		_routes.default.boardingStatuses(this);
		_routes.default.regions(this);
		_routes.default.countries(this);
	};
});