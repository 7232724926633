define('swap-system/mirage/models/rma', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Model.extend({
		file: (0, _emberCliMirage.belongsTo)('merchant'),
		rmaComments: (0, _emberCliMirage.hasMany)('rmaComment'),
		rmaDetails: (0, _emberCliMirage.hasMany)('rmaDetail'),
		rmaFees: (0, _emberCliMirage.hasMany)('rmaFee'),
		swap: (0, _emberCliMirage.belongsTo)('swap'),
		rmaReason: (0, _emberCliMirage.belongsTo)('rmaReason'),
		rmaApproval: (0, _emberCliMirage.belongsTo)('rmaApproval'),
		rmaStatus: (0, _emberCliMirage.belongsTo)('rmaStatus'),
		brand: (0, _emberCliMirage.belongsTo)('brand'),
		orderType: (0, _emberCliMirage.belongsTo)('orderType'),
		processorType: (0, _emberCliMirage.belongsTo)('processorType')
	});
});