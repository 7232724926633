define('swap-system/mirage/models/swap-approval', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Model.extend({
		file: (0, _emberCliMirage.belongsTo)('merchant'),
		swapApprovalDetails: (0, _emberCliMirage.hasMany)('swapApprovalDetail'),
		swapApprovalComments: (0, _emberCliMirage.hasMany)('swapApprovalComment'),
		swap: (0, _emberCliMirage.belongsTo)('swap'),
		brand: (0, _emberCliMirage.belongsTo)('brand'),
		swapApprovalStatus: (0, _emberCliMirage.belongsTo)('swapApprovalStatus'),
		orderType: (0, _emberCliMirage.belongsTo)('orderType'),
		processorType: (0, _emberCliMirage.belongsTo)('processorType'),
		merchant: (0, _emberCliMirage.belongsTo)('merchant')
	});
});