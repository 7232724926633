define('swap-system/mirage/routes/rma-approvals', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (server) {
		server.get('/rmaApprovals', function (_ref) {
			var rmaApprovals = _ref.rmaApprovals;

			return {
				rmaApprovals: rmaApprovals.all().models,
				meta: { itemsCount: rmaApprovals.all().models.length, pagesCount: Math.ceil(rmaApprovals.all().models.length / 10) }
			};
		});
		server.get('/rmaApprovals/:id');
		server.put('/rmaApprovals/:id', function (_ref2, request) {
			var rmaApprovals = _ref2.rmaApprovals,
			    rmas = _ref2.rmas,
			    merchants = _ref2.merchants,
			    brands = _ref2.brands;

			var id = request.params.id;
			var attrs = this.normalizedRequestAttrs();

			attrs.file = merchants.find(attrs.fileId);
			attrs.brand = brands.find(attrs.brandId);
			attrs.rma = rmas.find(attrs.rmaId);
			attrs.rmaFees = [];

			return rmaApprovals.find(id).update(attrs);
		});
		server.post('/rmaApprovals', function (_ref3) {
			var rmaApprovals = _ref3.rmaApprovals,
			    rmas = _ref3.rmas,
			    rmaReasons = _ref3.rmaReasons,
			    merchants = _ref3.merchants,
			    brands = _ref3.brands,
			    rmaApprovalStatuses = _ref3.rmaApprovalStatuses,
			    processorTypes = _ref3.processorTypes;

			var attrs = this.normalizedRequestAttrs();

			attrs.file = merchants.find(attrs.fileId);
			attrs.brand = brands.find(attrs.brandId);
			attrs.rmaApprovalStatus = rmaApprovalStatuses.find(attrs.rmaApprovalStatusId);
			if (attrs.rma) {
				attrs.rma = rmas.find(attrs.rma.id);
			}
			attrs.rmaFees = [];
			attrs.rmaReason = rmaReasons.find(attrs.rmaReasonId);
			attrs.processorType = processorTypes.find(attrs.processorTypeId);

			return rmaApprovals.create(attrs);
		});
		server.get('/rmaApprovals/countPending', function (_ref4) {
			var rmaApprovals = _ref4.rmaApprovals;

			var rmaApprovalsList = rmaApprovals.where({ rmaApprovalStatusId: 1 });
			return {
				status: 200,
				result: rmaApprovalsList.length
			};
		});
	};
});