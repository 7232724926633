define('swap-system/mirage/routes/rma-fees', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (server) {
		var _this = this;

		server.get('/rmaFees/', function (_ref, request) {
			var rmaFees = _ref.rmaFees;

			var id = request.queryParams.id;
			return rmaFees.where({ rmaId: id });
		});
		server.put('/rmaFees/:id', function (_ref2, request) {
			var rmaFees = _ref2.rmaFees;

			var id = request.params.id;
			var attrs = _this.normalizedRequestAttrs();

			delete attrs.modelName;
			delete attrs.rma;
			delete attrs.product;

			return rmaFees.find(id).update(attrs);
		});
	};
});