define('swap-system/mirage/models/product', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Model.extend({
		equipment: (0, _emberCliMirage.hasMany)('equipment'),
		swapDetails: (0, _emberCliMirage.hasMany)('swapDetail'),
		swapApprovalDetails: (0, _emberCliMirage.hasMany)('swapApprovalDetail'),
		rmaDetails: (0, _emberCliMirage.hasMany)('rmaDetail'),
		rmaApprovalDetails: (0, _emberCliMirage.hasMany)('rmaApprovalDetail'),
		adminPanelProductSubstitutionProducts: (0, _emberCliMirage.hasMany)('adminPanelProductSubstitutionProduct'),
		adminPanelProductSubstitutionSubproducts: (0, _emberCliMirage.hasMany)('adminPanelProductSubstitutionSubproduct')
	});
});