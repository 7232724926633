define('swap-system/models/swap-approval', ['exports', 'ember-data', 'ember-cp-validations', 'swap-system/mixins/models/commit-details', 'swap-system/validations/address-validations'], function (exports, _emberData, _emberCpValidations, _commitDetails, _addressValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo,
	    hasMany = _emberData.default.hasMany;


	var swapApprovalValidations = Ember.assign({
		denyReason: (0, _emberCpValidations.validator)('length', {
			max: 255
		}),
		file: (0, _emberCpValidations.validator)('presence', { presence: true }),
		swapApprovalDetails: [(0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('length', {
			min: 1,
			message: 'Select at least 1 equipment'
		})],
		swapApprovalStatus: (0, _emberCpValidations.validator)('presence', { presence: true }),
		isVip: (0, _emberCpValidations.validator)('presence', { presence: true })
	}, _addressValidations.default);
	var validations = (0, _emberCpValidations.buildValidations)(swapApprovalValidations);

	exports.default = Model.extend(validations, _commitDetails.default, {
		mid: attr('string'),
		dba: attr('string'),
		shippingAddress1: attr('string'),
		shippingAddress2: attr('string'),
		merchantAddresses: attr(),
		country: attr('string', { defaultValue: 'UNITED STATES OF AMERICA' }),
		shippingCity: attr('string'),
		shippingState: attr('string'),
		shippingZip: attr('string'),
		shippingExtendedZip: attr('string'),
		shippingTo: attr('string'),
		shippingAttention: attr('string'),
		shippingMethod: attr('string'),
		shippingPrice: attr('string', { defaultValue: 0 }),
		shippedFrom: attr('string'),
		createdBy: attr('string'),
		approvalBy: attr('string'),
		approvalAt: attr('date'),
		createdAt: attr('utc-format'),
		denyReason: attr(''),
		alertMerchant: attr('boolean'),
		alertVAR: attr('boolean'),
		isVip: attr('boolean', { defaultValue: false }),
		orderType: belongsTo('orderType'),
		processorType: belongsTo('processorType'),
		file: belongsTo('merchant'),
		swapApprovalDetails: hasMany('swapApprovalDetail'),
		swapApprovalComments: hasMany('swapApprovalComment'),
		swap: belongsTo('swap'),
		brand: belongsTo('brand'),
		swapApprovalStatus: belongsTo('swapApprovalStatus')
	});
});