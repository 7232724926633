define('swap-system/mirage/routes/default-shipping-address', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (server) {
		server.get('/defaultShippingAddresses');
	};
});