define('swap-system/mirage/routes/swap-approval-comments', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (server) {
		server.post('/swapApprovalComments', function (_ref) {
			var swapApprovalComments = _ref.swapApprovalComments,
			    swapApprovals = _ref.swapApprovals;

			try {
				var attrs = this.normalizedRequestAttrs();
				if (attrs.comment === null || attrs.comment === '') {
					throw new Error('Comment can not be empty');
				}

				attrs.swapApproval = swapApprovals.find(attrs.swapApprovalId);
				return swapApprovalComments.create(attrs);
			} catch (error) {
				return new _emberCliMirage.Response(500, {}, {
					error: {
						code: 500,
						message: error
					}
				});
			}
		});
	};
});