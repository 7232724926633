define('swap-system/mirage/routes/rma-approval-details', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (server) {
		server.get('/rmaApprovalDetails/lastManualSourceId', function (_ref) {
			var rmaApprovalDetails = _ref.rmaApprovalDetails;

			var detailsIds = rmaApprovalDetails.where({
				equipmentSource: 'Manual'
			}).models.map(function (model) {
				return model.equipmentId;
			});

			detailsIds.sort(function (a, b) {
				return b - a;
			});
			return { equipmentId: detailsIds.length > 0 ? detailsIds[0] : 0 };
		});
	};
});