define('swap-system/mirage/routes/swap-details', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (server) {
		server.put('/swapDetails/:id', function (_ref, request) {
			var swapDetails = _ref.swapDetails,
			    swaps = _ref.swaps,
			    merchants = _ref.merchants;

			var id = request.params.id;
			var attrs = this.normalizedRequestAttrs();
			delete attrs.modelName;

			attrs.swap = swaps.find(attrs.swapId);
			attrs.product = merchants.find(attrs.productId);

			return swapDetails.find(id).update(attrs);
		});
	};
});