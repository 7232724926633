define('swap-system/mirage/routes/brands', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (server) {
		server.get('/brands/:id');
		server.get('/brands');
	};
});