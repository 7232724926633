define('swap-system/mirage/routes/rmas', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (server) {
		server.get('/rmas', function (_ref) {
			var rmas = _ref.rmas;

			return {
				rmas: rmas.all().models,
				meta: { itemsCount: rmas.all().models.length, pagesCount: Math.ceil(rmas.all().models.length / 10) }
			};
		});
		server.get('/rmas/:id');
		server.put('/rmas/:id', function (_ref2, request) {
			var rmas = _ref2.rmas,
			    merchants = _ref2.merchants,
			    brands = _ref2.brands,
			    rmaReasons = _ref2.rmaReasons,
			    rmaStatuses = _ref2.rmaStatuses,
			    rmaApprovals = _ref2.rmaApprovals,
			    swaps = _ref2.swaps,
			    orderTypes = _ref2.orderTypes;

			try {
				var id = request.params.id;
				var attrs = this.normalizedRequestAttrs();

				if (attrs.file === null) {
					throw new Error('File can not be empty');
				}

				attrs.dueDate = getRMADueDate(attrs);
				delete attrs.rmaDetails;
				delete attrs.rmaFees;

				attrs.file = merchants.find(attrs.fileId);
				attrs.brand = brands.find(attrs.brandId);
				attrs.orderType = orderTypes.find(attrs.orderTypeId);
				attrs.rmaStatus = rmaStatuses.find(attrs.rmaStatusId);
				attrs.rmaReason = rmaReasons.find(attrs.rmaReasonId);

				if (attrs.rmaApprovalId) {
					attrs.rmaApproval = rmaApprovals.find(attrs.rmaApprovalId);
				}
				if (attrs.swap) {
					attrs.swap = swaps.find(attrs.swap.id);
				}

				return rmas.find(id).update(attrs);
			} catch (error) {
				return new _emberCliMirage.Response(500, {}, {
					error: {
						code: 500,
						message: error
					}
				});
			}
		});
		server.post('/rmas', function (_ref3) {
			var rmas = _ref3.rmas,
			    merchants = _ref3.merchants,
			    brands = _ref3.brands,
			    rmaReasons = _ref3.rmaReasons,
			    rmaStatuses = _ref3.rmaStatuses,
			    rmaApprovals = _ref3.rmaApprovals,
			    rmaDetails = _ref3.rmaDetails,
			    swaps = _ref3.swaps,
			    orderTypes = _ref3.orderTypes;

			var attrs = this.normalizedRequestAttrs();
			var details = attrs.rmaDetails || [];

			attrs.file = merchants.find(attrs.fileId);
			attrs.brand = brands.find(attrs.brandId);
			attrs.orderType = orderTypes.find(attrs.orderTypeId);
			attrs.rmaStatus = rmaStatuses.find(attrs.rmaStatusId);
			attrs.rmaReason = rmaReasons.find(attrs.rmaReasonId);
			attrs.rmaDetails = [];

			if (attrs.rmaApprovalId) {
				attrs.rmaApproval = rmaApprovals.find(attrs.rmaApprovalId);
			}

			if (attrs.swap) {
				attrs.swap = swaps.find(attrs.swap.id);
			}

			var rma = rmas.create(attrs);
			details.forEach(function (item) {
				item.rma = rma;
				rmaDetails.create(item);
			});

			return { rma: rma };
		});
	};

	/* eslint require-jsdoc: "off" */
	var getRMADueDate = function getRMADueDate(rma) {
		var sortedDetail = rma.rmaDetails.map(function (x) {
			if (!x.isReturned && !x.isReturnNotNeeded && !x.isBilled && x.dueOn) {
				return x;
			}
		}).sort(function (a, b) {
			return new Date(a.dueOn) - new Date(b.dueOn);
		});

		if (sortedDetail[0]) {
			return sortedDetail[0].dueOn;
		}
	};
});