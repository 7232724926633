define('swap-system/models/country', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr;
	exports.default = Model.extend({
		countryName: attr('string'),
		alpha2Code: attr('string'),
		numericCode: attr('number'),
		currencyCode: attr('string')
	});
});