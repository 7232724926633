define('swap-system/mirage/models/swap-rma-substitution-product', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Model.extend({
		product: (0, _emberCliMirage.belongsTo)('product'),
		swapRmaSubstitutionSubproducts: (0, _emberCliMirage.hasMany)('swapRmaSubstitutionSubproduct')
	});
});