define('swap-system/mirage/factories/product', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Factory.extend({
		modelNo: function modelNo() {
			return _emberCliMirage.faker.random.number();
		},
		source: function source() {
			return _emberCliMirage.faker.random.words();
		},
		productName: function productName(i) {
			return 'product' + i;
		},
		productType: function productType() {
			return _emberCliMirage.faker.random.words();
		},

		isSerialNeeded: false
	});
});