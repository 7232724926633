define('swap-system/mirage/factories/rma-detail', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Factory.extend({
		equipmentId: function equipmentId() {
			return _emberCliMirage.faker.random.number();
		},
		equipmentSource: function equipmentSource() {
			return _emberCliMirage.faker.random.word();
		},
		productName: function productName() {
			return _emberCliMirage.faker.random.word();
		},
		oldSerialNumber: function oldSerialNumber() {
			return _emberCliMirage.faker.random.uuid();
		}
	});
});