define('swap-system/mirage/routes/swap-approvals', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (server) {
		server.get('/swapApprovals', function (_ref) {
			var swapApprovals = _ref.swapApprovals;

			return {
				swapApprovals: swapApprovals.all().models,
				meta: { itemsCount: swapApprovals.all().models.length, pagesCount: Math.ceil(swapApprovals.all().models.length / 10) }
			};
		});
		server.get('/swapApprovals/:id');
		server.post('/swapApprovals', function (_ref2) {
			var swapApprovals = _ref2.swapApprovals,
			    swaps = _ref2.swaps,
			    merchants = _ref2.merchants,
			    brands = _ref2.brands,
			    swapApprovalStatuses = _ref2.swapApprovalStatuses,
			    processorTypes = _ref2.processorTypes;

			try {
				var attrs = this.normalizedRequestAttrs();

				attrs.file = merchants.find(attrs.fileId);
				attrs.brand = brands.find(attrs.brandId);
				attrs.swapApprovalStatus = swapApprovalStatuses.find(attrs.swapApprovalStatusId);
				if (attrs.swap) {
					attrs.swap = swaps.find(attrs.swap.id);
				}
				attrs.processorType = processorTypes.find(attrs.processorTypeId);
				return swapApprovals.create(attrs);
			} catch (error) {
				return new _emberCliMirage.Response(500, {}, {
					error: {
						code: 500,
						message: error
					}
				});
			}
		});
		server.put('/swapApprovals/:id', function (_ref3, request) {
			var swapApprovals = _ref3.swapApprovals,
			    swaps = _ref3.swaps,
			    merchants = _ref3.merchants,
			    brands = _ref3.brands,
			    swapApprovalStatuses = _ref3.swapApprovalStatuses;

			try {
				var id = request.params.id;
				var attrs = this.normalizedRequestAttrs();

				if (attrs.fileId) {
					delete attrs.merchantAddresses;
				}

				attrs.file = merchants.find(attrs.fileId);
				attrs.brand = brands.find(attrs.brandId);
				attrs.swapApprovalStatus = swapApprovalStatuses.find(attrs.swapApprovalStatusId);
				if (attrs.swap) {
					attrs.swap = swaps.find(attrs.swap.id);
				}

				return swapApprovals.find(id).update(attrs);
			} catch (error) {
				return new _emberCliMirage.Response(500, {}, {
					error: {
						code: 500,
						message: error
					}
				});
			}
		});

		server.get('/swapApprovals/countPending', function (_ref4, request) {
			var swapApprovals = _ref4.swapApprovals;

			var token = request.requestHeaders['auth-token'];
			var swapApprovalsList = swapApprovals.where({ swapApprovalStatusId: 1 });
			if (token === '') {
				return new _emberCliMirage.Response(500, {}, {
					error: {
						code: 500,
						message: 'token is empty'
					}
				});
			}
			return {
				status: 200,
				result: swapApprovalsList.length
			};
		});
	};
});