define('swap-system/mirage/routes/merchants', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (server) {
		server.get('/merchants/:id');
		server.get('/merchants', function (_ref, request) {
			var merchants = _ref.merchants;

			var mid = request.queryParams.mid;
			return merchants.where({ mid: mid });
		});
	};
});