define('swap-system/controllers/admin-panel-altered-shipping-address', ['exports', 'swap-system/mixins/controllers/save-ticket', 'swap-system/mixins/controllers/approve-ticket', 'swap-system/mixins/controllers/admin-panel-altered-shipping-address'], function (exports, _saveTicket, _approveTicket, _adminPanelAlteredShippingAddress) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var OTHER_ADDRESS_NAME = 'Other';

	exports.default = Ember.Controller.extend(_saveTicket.default, _approveTicket.default, _adminPanelAlteredShippingAddress.default, {
		session: Ember.inject.service(),
		user: Ember.inject.service(),
		baseOfficeAddress: null,
		isSaveButtonDisabled: Ember.computed('isLocked', 'model.alteredShippingAddress.isLocked', 'selectedDefaultOfficeAddress.{attention,addressLine1,addressLine2,city,state,type,zip}', 'baseOfficeAddress', function () {
			var isLocked = this.get('isLocked');
			var defaultIsLocked = this.get('model.alteredShippingAddress.isLocked');
			var selectedDefaultOfficeAddress = this.get('selectedDefaultOfficeAddress');
			var baseOfficeAddress = this.get('baseOfficeAddress');

			if (defaultIsLocked !== isLocked) {
				return false;
			}

			var isDataChanged = Object.keys(selectedDefaultOfficeAddress).reduce(function (result, key) {
				if (baseOfficeAddress[key] !== selectedDefaultOfficeAddress[key]) {
					return true;
				}

				return result;
			}, false);

			return !isDataChanged;
		}),
		resetForm: function resetForm() {
			var _get$getProperties = this.get('model.alteredShippingAddress').getProperties(['defaultShippingAddress', 'officeAddresses', 'isLocked']),
			    defaultShippingAddress = _get$getProperties.defaultShippingAddress,
			    officeAddresses = _get$getProperties.officeAddresses,
			    isLocked = _get$getProperties.isLocked;

			var selectedDefaultOfficeAddress = officeAddresses.findBy('type', defaultShippingAddress.get('addressName'));
			var baseOfficeAddress = Object.assign({}, selectedDefaultOfficeAddress);

			this.setProperties({
				selectedDefaultOfficeAddress: selectedDefaultOfficeAddress,
				officeAddresses: officeAddresses,
				baseOfficeAddress: baseOfficeAddress,
				isLocked: isLocked
			});
		},

		fillAlteredShippingAddressByUpdates: function fillAlteredShippingAddressByUpdates() {
			var selectedDefaultOfficeAddress = this.selectedDefaultOfficeAddress,
			    isLocked = this.isLocked;

			var alteredShippingAddress = this.get('model.alteredShippingAddress');
			var alteredShippingAddressProperties = {
				isLocked: isLocked
			};

			if (selectedDefaultOfficeAddress.type === OTHER_ADDRESS_NAME) {
				var attention = selectedDefaultOfficeAddress.attention,
				    addressLine1 = selectedDefaultOfficeAddress.addressLine1,
				    addressLine2 = selectedDefaultOfficeAddress.addressLine2,
				    city = selectedDefaultOfficeAddress.city,
				    state = selectedDefaultOfficeAddress.state,
				    country = selectedDefaultOfficeAddress.country,
				    zip = selectedDefaultOfficeAddress.zip;

				var otherAddressParams = {
					shippingAttention: attention,
					shippingAddress1: addressLine1,
					shippingAddress2: addressLine2,
					shippingCity: city,
					shippingState: state,
					country: country,
					shippingZip: zip
				};

				alteredShippingAddressProperties = Object.assign(alteredShippingAddressProperties, otherAddressParams);
			}

			alteredShippingAddress.setProperties(alteredShippingAddressProperties);
		}
	});
});