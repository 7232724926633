define('swap-system/mirage/factories/boarding-status', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Factory.extend({
		displayName: function displayName(i) {
			return _emberCliMirage.faker.list.cycle('Boarding not needed', 'Pending', 'Boarded', 'Boarding failed')(i);
		}
	});
});