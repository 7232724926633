define('swap-system/enums/countryConstants', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        DEFAULT_COUNTRY: 'UNITED STATES OF AMERICA',
        INVALID_ZIP_ERROR_MESSAGE: 'Invalid Zip, unable to save',
        INVALID_STATE_ERROR_MESSAGE: 'Invalid State, unable to save',
        US_ZIP_MAX: 5,
        INTL_ZIP_MAX: 8,
        NON_USA_DEFAULT_SHIPPING_METHOD: 'Ground'
    };
});