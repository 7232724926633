define('swap-system/mirage/routes/admin-panel-products', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (server) {
		server.get('/adminPanel/products', function (data) {
			var adminPanelProducts = data.adminPanelProducts;


			return {
				adminPanelProducts: adminPanelProducts.all().models,
				meta: { itemsCount: adminPanelProducts.all().models.length, pagesCount: Math.ceil(adminPanelProducts.all().models.length / 10) }
			};
		});
		server.get('/adminPanel/products/:id', function (_ref, request) {
			var adminPanelProducts = _ref.adminPanelProducts,
			    adminPanelProductSubproducts = _ref.adminPanelProductSubproducts,
			    adminPanelProductSubstitutionProducts = _ref.adminPanelProductSubstitutionProducts,
			    adminPanelProductSubstitutionSubproducts = _ref.adminPanelProductSubstitutionSubproducts;

			var id = request.params.id;
			var adminPanelProduct = adminPanelProducts.find(id);
			var subProducts = adminPanelProductSubproducts.where({ adminPanelProductId: id });

			var availableSubstututions = adminPanelProductSubstitutionProducts.where({ adminPanelProductId: id });
			var substitutionProductIds = availableSubstututions.models.map(function (substitutionProduct) {
				return substitutionProduct.id;
			});
			var substitutionSubproducts = adminPanelProductSubstitutionSubproducts.where({
				adminPanelProductSubstitutionProductId: substitutionProductIds
			});

			return {
				adminPanelProduct: adminPanelProduct,
				adminPanelProductSubproducts: subProducts.models,
				adminPanelProductSubstitutionProducts: availableSubstututions.models,
				adminPanelProductSubstitutionSubproducts: substitutionSubproducts.models
			};
		});
		server.put('/adminPanel/products/:id', function (_ref2, request) {
			var adminPanelProducts = _ref2.adminPanelProducts;
			var id = request.params.id;


			return adminPanelProducts.find(id);
		});
	};
});