define('swap-system/mirage/factories/rma-approval', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Factory.extend({
		alertMerchant: false,
		alertVAR: true,
		createdAt: '2017-10-23T19:02:08.000Z',
		createdBy: function createdBy() {
			return _emberCliMirage.faker.internet.userName();
		},
		dba: function dba() {
			return _emberCliMirage.faker.company.companyName();
		},
		mid: function mid() {
			return _emberCliMirage.faker.random.number();
		},
		countPending: function countPending() {
			return Ember.Object.create({
				result: 0
			});
		}
	});
});