define('swap-system/mirage/factories/equipment', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Factory.extend({
		orderId: function orderId() {
			return _emberCliMirage.faker.random.number();
		},
		orderDetailId: function orderDetailId() {
			return _emberCliMirage.faker.random.number();
		},
		modelNo: function modelNo() {
			return _emberCliMirage.faker.random.uuid();
		},
		oldSerialNo: function oldSerialNo() {
			return _emberCliMirage.faker.random.uuid();
		},
		source: function source() {
			return _emberCliMirage.faker.random.words();
		},
		productName: function productName() {
			return _emberCliMirage.faker.random.words();
		},

		rmaCost: '2100',
		isSerialNeeded: false
	});
});