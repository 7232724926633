define('swap-system/mirage/factories/merchant', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Factory.extend({
		mid: function mid() {
			return _emberCliMirage.faker.random.number();
		},
		isoOffice: function isoOffice() {
			return _emberCliMirage.faker.name.firstName();
		},
		addresses: function addresses() {
			return [{
				addressType: 'dba',
				address1: 'dba Address 1',
				address2: 'dba Address 2',
				city: 'ALLENTOWN',
				state: 'PA',
				country: 'UNITED STATES OF AMERICA',
				zip: '11111'
			}, {
				addressType: 'legal',
				address1: 'Legal Address 1',
				address2: 'Legal Address 2',
				city: 'New York',
				state: 'NY',
				country: 'UNITED STATES OF AMERICA',
				zip: '22222'
			}, {
				addressType: 'ISO',
				address1: 'ISO Address 1',
				address2: 'ISO Address 2',
				city: 'San Francisco',
				state: 'CA',
				country: 'UNITED STATES OF AMERICA',
				zip: '33333'
			}];
		},
		dbaAddress1: function dbaAddress1() {
			return _emberCliMirage.faker.address.streetAddress();
		},
		dbaAddress2: function dbaAddress2() {
			return _emberCliMirage.faker.address.secondaryAddress();
		},
		dbaCity: function dbaCity() {
			return _emberCliMirage.faker.address.city();
		},
		dbaState: function dbaState() {
			return _emberCliMirage.faker.address.state();
		},
		dbaCountry: function dbaCountry() {
			return _emberCliMirage.faker.address.country();
		},
		dbaName: function dbaName() {
			return _emberCliMirage.faker.company.companyName();
		},
		dbaZip: function dbaZip() {
			return _emberCliMirage.faker.address.zipCode().substring(0, 5);
		},
		ownerFirstName: function ownerFirstName() {
			return _emberCliMirage.faker.name.firstName();
		},
		ownerLastName: function ownerLastName() {
			return _emberCliMirage.faker.name.lastName();
		},

		associate: 'test',
		chain: 'test',
		status: 700,
		bankRecordExists: true
	});
});