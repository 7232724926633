define('swap-system/mirage/routes/swaps', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (server) {
		server.get('/swaps', function (_ref) {
			var swaps = _ref.swaps;

			return {
				swaps: swaps.all().models,
				meta: { itemsCount: swaps.all().models.length, pagesCount: Math.ceil(swaps.all().models.length / 10) }
			};
		});
		server.get('/swaps/:id');
		server.put('/swaps/:id', function (_ref2, request) {
			var swaps = _ref2.swaps,
			    merchants = _ref2.merchants,
			    brands = _ref2.brands,
			    rmas = _ref2.rmas,
			    swapStatuses = _ref2.swapStatuses,
			    swapApprovals = _ref2.swapApprovals,
			    orderTypes = _ref2.orderTypes,
			    swapDetails = _ref2.swapDetails;

			var id = request.params.id;
			var attrs = this.normalizedRequestAttrs();

			delete attrs.merchantAddresses;

			attrs.orderType = orderTypes.find(attrs.orderTypeId);
			attrs.file = merchants.find(attrs.fileId);
			attrs.brand = brands.find(attrs.brandId);
			attrs.swapStatus = swapStatuses.find(attrs.swapStatusId);
			attrs.swapApproval = swapApprovals.find(attrs.swapApprovalId);

			if (attrs.rmaId) {
				attrs.rma = rmas.find(attrs.rmaId);
			}

			if (attrs.submittedBy === 'fakeUser') {
				attrs.flaggedNotificationError = true;
			}

			if (attrs.swapDetails) {
				attrs.swapDetails.forEach(function (item, index) {
					attrs.swapDetails[index] = swapDetails.find(item.id);
				});
			}

			return swaps.find(id).update(attrs);
		});
		server.post('/swaps', function (_ref3) {
			var swaps = _ref3.swaps,
			    rmas = _ref3.rmas,
			    merchants = _ref3.merchants,
			    brands = _ref3.brands,
			    swapApprovals = _ref3.swapApprovals,
			    swapStatuses = _ref3.swapStatuses,
			    orderTypes = _ref3.orderTypes;

			var attrs = this.normalizedRequestAttrs();

			attrs.file = merchants.find(attrs.fileId);
			attrs.brand = brands.find(attrs.brandId);
			attrs.orderType = orderTypes.find(attrs.orderTypeId);
			if (attrs.rmaId) {
				attrs.rma = rmas.find(attrs.rmaId);
			}
			attrs.swapStatus = swapStatuses.find(attrs.swapStatusId);
			attrs.swapApproval = swapApprovals.find(attrs.swapApprovalId);

			return swaps.create(attrs);
		});
		server.get('/swaps/countFlags', function (_ref4) {
			var swaps = _ref4.swaps;

			var swapList = swaps.where({ swapStatusId: 1 });
			return {
				result: swapList.length
			};
		});
		server.get('/swaps/countAwaitingFulfillment', function (_ref5) {
			var swaps = _ref5.swaps;

			var swapList = swaps.where({ swapStatusId: 3 });
			return {
				result: swapList.length
			};
		});
	};
});