define('swap-system/mirage/routes/rma-details', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (server) {
		server.get('/rmaDetails', function (_ref, request) {
			var rmaDetails = _ref.rmaDetails;

			var id = request.queryParams.id;
			return rmaDetails.where({ rmaId: id });
		});
		server.put('/rmaDetails/:id', function (_ref2, request) {
			var rmaDetails = _ref2.rmaDetails;

			var id = request.params.id;
			var attrs = this.normalizedRequestAttrs();
			delete attrs.modelName;

			delete attrs.rma;
			delete attrs.product;

			return rmaDetails.find(id).update(attrs);
		});
	};
});