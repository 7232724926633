define('swap-system/routes/swap', ['exports', 'swap-system/mixins/routes/authenticated-route-mixin', 'swap-system/mixins/routes/required-group-mixin', 'swap-system/mixins/routes/confirmation-modal', 'swap-system/mixins/routes/log-loader', 'swap-system/enums/userGroups', 'swap-system/enums/countryConstants'], function (exports, _authenticatedRouteMixin, _requiredGroupMixin, _confirmationModal, _logLoader, _userGroups, _countryConstants) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requiredGroupMixin.default, _logLoader.default, _confirmationModal.default, {
		requiredGroup: _userGroups.default.swapUser,
		merchant: Ember.inject.service(),
		titleToken: function titleToken(model) {
			return 'Swap #' + model.swap.id;
		},
		model: function model(params) {
			var _this = this;

			this.store.unloadAll('swapRmaSubstitutionSubproduct');

			return this.store.findRecord('swap', params.id, { reload: true }).then(function (swap) {
				_this.set('merchant.id', swap.get('mid'));

				return Ember.RSVP.hash({
					swap: swap,
					swapStatuses: _this.store.findAll('swapStatus'),
					swapApproval: _this.store.findRecord('swapApproval', swap.get('swapApproval.id')),
					swapApprovalStatuses: _this.store.findAll('swapApprovalStatus'),
					brands: _this.store.findAll('brand'),
					substitutionProducts: _this.store.findAll('substitutionProduct'),
					substitutionSubproducts: _this.store.findAll('substitutionSubproduct'),
					processorTypes: _this.store.findAll('processorType'),
					rmaStatuses: _this.store.findAll('rmaStatus'),
					orderTypes: _this.store.findAll('orderType'),
					conditionTypes: _this.store.findAll('conditionType'),
					merchant: _this.store.queryRecord('merchant', { mid: swap.get('mid') }),
					products: _this.store.findAll('product'),
					registrableDevices: _this.store.findAll('registrableDevice'),
					boardingStatuses: _this.store.findAll('boardingStatus'),
					regions: _this.store.findAll('region'),
					countries: _this.store.findAll('country'),
					logs: null,
					isLoading: true
				}).then(function (model) {
					model.swap.set('merchantAddresses', model.merchant.get('addresses'));
					if (!model.swap.get('country')) {
						model.swap.set('country', _countryConstants.default.DEFAULT_COUNTRY);
					}
					return model;
				});
			});
		},
		setupController: function setupController(controller, model) {
			this._super(controller, model);
			controller.set('comment', '');
			model.isLoading = false;
		}
	});
});