define('swap-system/mirage/factories/swap-approval', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Factory.extend({
		alertMerchant: true,
		alertVAR: false,
		createdAt: '2017-10-23T19:02:08.000Z',
		attention: function attention() {
			return _emberCliMirage.faker.name.firstName() + ' ' + _emberCliMirage.faker.name.lastName();
		},
		createdBy: function createdBy() {
			return _emberCliMirage.faker.internet.userName();
		},
		dba: function dba() {
			return _emberCliMirage.faker.company.companyName();
		},

		isApprovalDone: false,
		isApproved: false,
		mid: function mid() {
			return _emberCliMirage.faker.random.number();
		},
		merchantAddresses: function merchantAddresses() {
			return [{
				address1: 'DBA address 1',
				address2: 'DBA address 2',
				city: 'DBA City',
				state: 'DBA State',
				country: 'DBA Country',
				zip: 'DBA Zip'
			}, {
				address1: 'Legal address 1',
				address2: 'Legal address 2',
				city: 'Legal City',
				state: 'Legal State',
				country: 'Legal Country',
				zip: 'Legal Zip'
			}, {
				address1: 'ISO address 1',
				address2: 'ISO address 2',
				city: 'ISO City',
				state: 'ISO State',
				country: 'ISO Country',
				zip: 'ISO Zip'
			}];
		},
		country: function country() {
			return _emberCliMirage.faker.address.country();
		},
		shippingAddress1: function shippingAddress1() {
			return _emberCliMirage.faker.address.streetAddress();
		},
		shippingAddress2: function shippingAddress2() {
			return _emberCliMirage.faker.address.secondaryAddress();
		},
		shippingCity: function shippingCity() {
			return _emberCliMirage.faker.address.city();
		},
		shippingMethod: function shippingMethod(i) {
			return _emberCliMirage.faker.list.random('Ground', '2nd Day', 'Next Day', 'Saturday', 'Saturday 10AM', 'On Site')(i);
		},
		shippingState: function shippingState() {
			return _emberCliMirage.faker.address.state();
		},
		shippingCountry: function shippingCountry() {
			return _emberCliMirage.faker.address.country();
		},
		shippingTo: function shippingTo() {
			return _emberCliMirage.faker.company.companyName();
		},
		shippingZip: function shippingZip() {
			return _emberCliMirage.faker.address.zipCode().substring(0, 5);
		},
		shippedFrom: function shippedFrom() {
			return null;
		},
		countPending: function countPending() {
			return Ember.Object.create({
				result: 15
			});
		}
	});
});