define('swap-system/mirage/factories/swap', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Factory.extend({
		alertMerchant: true,
		alertVAR: false,
		attention: function attention() {
			return _emberCliMirage.faker.name.firstName() + ' ' + _emberCliMirage.faker.name.lastName();
		},

		createdAt: '2017-10-23T19:02:08.000Z',
		createdBy: function createdBy() {
			return _emberCliMirage.faker.internet.userName();
		},
		dba: function dba() {
			return _emberCliMirage.faker.company.companyName();
		},
		mid: function mid() {
			return _emberCliMirage.faker.random.number();
		},
		country: function country() {
			return _emberCliMirage.faker.address.country();
		},
		shippingAddress1: function shippingAddress1() {
			return _emberCliMirage.faker.address.streetAddress();
		},
		shippingAddress2: function shippingAddress2() {
			return _emberCliMirage.faker.address.secondaryAddress();
		},
		shippingCity: function shippingCity() {
			return _emberCliMirage.faker.address.city();
		},
		shippingMethod: function shippingMethod(i) {
			return _emberCliMirage.faker.list.random('Ground', '2nd Day', 'Next Day', 'Saturday', 'Saturday 10AM', 'On Site')(i);
		},
		shippingState: function shippingState() {
			return _emberCliMirage.faker.address.state();
		},
		shippingCountry: function shippingCountry() {
			return _emberCliMirage.faker.address.country();
		},
		shippingTo: function shippingTo() {
			return _emberCliMirage.faker.company.companyName();
		},
		shippingZip: function shippingZip() {
			return _emberCliMirage.faker.address.zipCode().substring(0, 5);
		},
		shippedFrom: function shippedFrom() {
			return null;
		}
	});
});