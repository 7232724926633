define('swap-system/mirage/routes/altered-shipping-addresses', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (server) {
		//eslint-disable-next-line no-empty-pattern
		server.post('/adminPanel/shippingAddresses', function (_ref, request) {
			_objectDestructuringEmpty(_ref);

			var alteredShippingAddress = JSON.parse(request.requestBody).alteredShippingAddress;
			alteredShippingAddress.id = ID_SIMULATION;

			return { alteredShippingAddress: alteredShippingAddress };
		});
		server.put('/adminPanel/shippingAddresses/:id', function (_ref2, request) {
			var alteredShippingAddresses = _ref2.alteredShippingAddresses;

			var alteredShippingAddress = JSON.parse(request.requestBody).alteredShippingAddress;
			var officeId = alteredShippingAddress.officeId;
			alteredShippingAddresses.findBy({ officeId: officeId }).update(alteredShippingAddress);
			var updatedAlteredShippingAddress = alteredShippingAddresses.findBy({ officeId: officeId });
			updatedAlteredShippingAddress.attrs.officeAddresses = DEFAULT_OFFICE_ADDRESSES;

			return {
				alteredShippingAddress: updatedAlteredShippingAddress
			};
		});
		server.get('/adminPanel/shippingAddresses/:officeId', function (_ref3, request) {
			var alteredShippingAddresses = _ref3.alteredShippingAddresses;

			var officeId = request.params.officeId;
			var alteredShippingAddress = alteredShippingAddresses.findBy({ officeId: officeId });
			alteredShippingAddress.attrs.officeAddresses = DEFAULT_OFFICE_ADDRESSES;

			return {
				alteredShippingAddress: alteredShippingAddress
			};
		});
		server.get('/adminPanel/shippingAddresses', function (_ref4) {
			var alteredShippingAddresses = _ref4.alteredShippingAddresses;

			return {
				alteredShippingAddresses: alteredShippingAddresses.all().models,
				meta: {
					itemsCount: alteredShippingAddresses.all().models.length,
					pagesCount: Math.ceil(alteredShippingAddresses.all().models.length / 10)
				}
			};
		});
	};

	function _objectDestructuringEmpty(obj) {
		if (obj == null) throw new TypeError("Cannot destructure undefined");
	}

	/* eslint require-jsdoc: "off" */
	var ID_SIMULATION = 100;
	var DEFAULT_OFFICE_ADDRESSES = [{ id: 1, type: 'DBA' }, { id: 2, type: 'ISO' }, { id: 3, type: 'Other' }];
});