define('swap-system/mixins/controllers/country-custom-validation', ['exports', 'swap-system/enums/countryConstants', 'swap-system/enums/states'], function (exports, _countryConstants, _states) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		getMaxZipLengthForCountry: function getMaxZipLengthForCountry(currentCountry) {
			return currentCountry !== _countryConstants.default.DEFAULT_COUNTRY ? _countryConstants.default.INTL_ZIP_MAX : _countryConstants.default.US_ZIP_MAX;
		},


		/**
      * Check shipping zip validity
      * @method        checkZipValid
      * @memberOf    countryCustomValidation
      * @param        {Number} zipLength The current length of the zip string
      * @param        {Number} maxZipLength The max allowed length of the zip string
      * @returns    {Boolean}             Is zip valid
      */
		checkZipValid: function checkZipValid(zipLength, maxZipLength) {
			return zipLength <= maxZipLength;
		},


		/**
      * Check shipping state validity
      * @method        checkStateValid
      * @memberOf    countryCustomValidation
      * @param        {string} selectedState The currently selected state in shortname format (2 character state code)
      * @param        {string} selectedCountry The currently selected country, formatted as country name in all caps
      * @returns    {Boolean}             Is state valid
      */
		checkStateValid: function checkStateValid(selectedState, selectedCountry) {
			if (selectedCountry === _countryConstants.default.DEFAULT_COUNTRY) {
				if (!_states.default.find(function (state) {
					return state.shortName === selectedState;
				})) {
					return false;
				}
			}
			return true;
		},
		countryChanged: function countryChanged(sender, key) {
			// eslint-disable-next-line prefer-template
			var modelRoot = key.split('.country')[0];
			if (this.get('isDefaultCountry')) {
				return;
			}

			//non-USA (non-default) country form changes below:
			this.set(modelRoot + '.shippingMethod', _countryConstants.default.NON_USA_DEFAULT_SHIPPING_METHOD);

			//set createRma to false for groupEquipment (create swap screen)
			if (this.get('groupEquipment')) {
				this.get('groupEquipment').forEach(function (eq) {
					eq.items.forEach(function (item) {
						item.set('createRma', false);
					});
				});
			}

			//set createRMA to false for swapApprovalDetails (swap approval screen)
			if (this.get('model.swapApproval.swapApprovalDetails')) {
				this.get('model.swapApproval.swapApprovalDetails').forEach(function (eq) {
					eq.set('returnEquipment', false);
				});
			}
		}
	});
});