define('swap-system/mirage/factories/brand', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Factory.extend({
		name: function name(i) {
			return _emberCliMirage.faker.list.cycle('Lighthouse Network', 'Restaurant Manager')(i);
		},
		logoUrl: function logoUrl(i) {
			var variants = ['https://s3.amazonaws.com/ht-supply-system/logos/lighthouse.png', 'https://s3.amazonaws.com/ht-supply-system/logos/rm.png'];
			return _emberCliMirage.faker.list.cycle(variants[0], variants[1])(i);
		},

		chain: 'test',
		emailSender: 'test',
		emailFooter: 'test',
		notifyMerchantDefault: function notifyMerchantDefault(i) {
			return _emberCliMirage.faker.list.cycle(1, 0)(i);
		},
		notifyVARDefault: function notifyVARDefault(i) {
			return _emberCliMirage.faker.list.cycle(0, 1)(i);
		}
	});
});