define('swap-system/mirage/routes/serial-history', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (server) {
		server.get('/serialHistory/count', function (_ref, request) {
			var swapRmaSubstitutionProducts = _ref.swapRmaSubstitutionProducts,
			    swapRmaSubstitutionSubproducts = _ref.swapRmaSubstitutionSubproducts;

			var productsCount = swapRmaSubstitutionProducts.where({
				newSerialNumber: request.queryParams.serialNumber,
				productId: request.queryParams.productId
			}).models.length;
			var subproductsCount = swapRmaSubstitutionSubproducts.where({
				newSerialNumber: request.queryParams.serialNumber,
				productId: request.queryParams.productId
			}).models.length;

			return new _emberCliMirage.Response(200, {}, {
				result: productsCount + subproductsCount
			});
		});
	};
});