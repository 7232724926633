define('swap-system/mirage/models/swap', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Model.extend({
		file: (0, _emberCliMirage.belongsTo)('merchant'),
		brand: (0, _emberCliMirage.belongsTo)('brand'),
		swapDetails: (0, _emberCliMirage.hasMany)('swapDetail'),
		swapComments: (0, _emberCliMirage.hasMany)('swapComment'),
		rma: (0, _emberCliMirage.belongsTo)('rma'),
		swapApproval: (0, _emberCliMirage.belongsTo)('swapApproval'),
		swapStatus: (0, _emberCliMirage.belongsTo)('swapStatus'),
		orderType: (0, _emberCliMirage.belongsTo)('orderType'),
		processorType: (0, _emberCliMirage.belongsTo)('processorType'),
		merchant: (0, _emberCliMirage.belongsTo)('merchant')
	});
});