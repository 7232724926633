define('swap-system/mirage/factories/rma-approval-comment', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCliMirage.Factory.extend({
		comment: function comment() {
			return _emberCliMirage.faker.random.words();
		},
		createdBy: function createdBy() {
			return _emberCliMirage.faker.internet.userName();
		},

		createdAt: '2017-10-23T19:02:08.000Z'
	});
});